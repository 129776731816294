import React from "react"
import Layout from "../components/layout"
import style from "./page.module.css"
import {graphql} from "gatsby"

const CareerPage = ({data}) => {
  const { markdownRemark } = data
  const {  html } = markdownRemark
  return (
      <Layout>
    <div className={style.content} >
     <div dangerouslySetInnerHTML={{ __html: html }}/> 
    </div>
    </Layout>
  )
}
export const query = graphql`
query CareerQuery {
  markdownRemark(fileAbsolutePath: {regex: "/career.md/"}) {
    html
    frontmatter {
      title
    }
  }
}
`
export default CareerPage